import { TextField, Select, Button, Box, Typography, MenuItem, FormControl, InputLabel } from '@mui/material'
import useCreateAcct from './hooks/useCreateAcct';
import { useHistory } from 'react-router-dom';

import { OrgConfig } from '../../shared/types/orgConfig';

interface CreateAccountFormProps {
  orgConfig: OrgConfig;
  subdomain: string;
  setLoggedIn: (loggedIn: boolean) => void;
}

const CreateAccountForm: React.FC<CreateAccountFormProps> = ({ orgConfig, subdomain, setLoggedIn }) => {  
  const { formData, errors, handleChange, handleSubmit } = useCreateAcct(subdomain, setLoggedIn)

  const history = useHistory(); 

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, margin: 'auto',  bgcolor: "white", padding: 2, borderRadius: 2 }}>
      <Typography variant="h5" component="h1" align='center' gutterBottom>
        Sign Up
      </Typography>
      <TextField
        fullWidth
        margin="normal"
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        required
        error={!!errors.email}
        helperText={errors.email}
      />
    { orgConfig && orgConfig.classes &&
      <FormControl fullWidth margin="normal">
        <InputLabel>Your class</InputLabel>
        <Select
            variant="outlined"
            required
            name="cohort"
            label="Your Class"
            id="cohort"
            value={formData.cohort}
            onChange={handleChange}
            error={!!errors.cohort}
        >
            {
            orgConfig.classes.map((item, index) => {
                return <MenuItem key={item+index} value={item}>{item}</MenuItem>
            })
            }
        </Select>
      </FormControl>
    }

      <TextField
        fullWidth
        margin="normal"
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        required
        error={!!errors.password}
        helperText={errors.password}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Confirm Password"
        name="confirmPassword"
        type="password"
        value={formData.confirmPassword}
        onChange={handleChange}
        required
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
      />
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        Create Account
      </Button>
    </Box>
  )
}

export default CreateAccountForm