import React from 'react'

import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox'

import ArticlePreview from '../../components/articlePreview/ArticlePreview'

import './Articles.scss'

interface Article {
  title: string
  subtitle: string
  articleSlug: string
  author: string
  authorImage: string
  image: string
  tags: string[]
}

const articleData: Article[] = [
  {  
    title: 'Building a Quick Email Feature to Nudge Users Based on a SQL Query',
    subtitle: 'Build a quick email feature leveraging NPM packages to nudge users to keep up a streak and earn a badge for consistency.',
    articleSlug: 'building-a-quick-email-feature-to-nudge-users-based-on-a-sql-query-81a06204d1e6',
    author: 'Jordan Manley',
    authorImage: '/images/jordan_manley.jpeg',
    image: '/articles/badge.png',
    tags: ["SQL", "Software Development"]
  },
  {  
    title: 'Landing Your First Job as a Software Developer',
    subtitle: 'For those of us who have taken a non-traditional path to software development, we need to work extra hard to stand out in a crowded sea of candidates.',
    articleSlug: 'landing-your-first-job-as-a-software-developer-f8424035f1de',
    author: 'Jordan Manley',
    authorImage: '/images/jordan_manley.jpeg',
    image: '/articles/offer-letter.png',
    tags: ["Learn To Code", "First Job"]
  }, 
  {
    title: 'Leading With Your Work - One Amazing Application',
    subtitle: 'For those of us who have taken a non-traditional path to software development, we need to work extra hard to stand out in a crowded sea of candidates.',
    articleSlug: 'leading-with-your-work-one-amazing-application-6378f9f3596b',
    author: 'Jordan Manley',
    authorImage: '/images/jordan_manley.jpeg',
    image: '/articles/app-copy.jpeg',
    tags: ["Learn To Code", "First Job"]
  }, 
  {
    title: 'Syncing Badges When Data Goes Haywire',
    subtitle: 'Build a feature to sync badges with past user data to ensure that users who have earned a badge in the past are still awarded the badge.',
    articleSlug: 'syncing-badges-when-data-goes-haywire-895acbcfa91c',
    author: 'Jordan Manley',
    authorImage: '/images/jordan_manley.jpeg', 
    image: '/articles/sql.jpeg',
    tags: ["Software Development"]
  }
]

const Articles: React.FC = () => {
  return (
    <ShadowBox className="articles" title="CodeTrack Articles">
      <div className="articles__list">
        {articleData.map((article, index) => (
          <ArticlePreview key={index} article={article} />
        ))}
      </div>
    </ShadowBox>
  )
}

export default Articles