import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '../../utilComponents/modal/Modal';
import './battingCageIntroModal.scss';

interface BattingCageIntroModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

const BattingCageIntroModal: React.FC<BattingCageIntroModalProps> = ({ showModal, setShowModal }) => {
    const history = useHistory(); 

    const gotItClick = () => {
        history.push('/battingcage'); 
    };

    return (
        <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            title=""
            customStyling={true}
            showSkipButton={false}
        >
            <div className="howDoesItWork_battingCageIntroModal">
                <h1 className="howDoesItWork_battingCageIntroModal__title">Batting Cage Intro</h1>
                <div className="howDoesItWork_battingCageIntroModal__content">
                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                        <iframe 
                            src="https://www.loom.com/embed/39c636bcfb1b4acca4c513a6619c337d?sid=5afa735e-8bbb-418c-9074-f435a1d3634f" 
                            frameBorder="0" 
                            allowFullScreen
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        ></iframe>
                    </div>
                </div>
                <div className="howDoesItWork_battingCageIntroModal__buttons">
                    <button className="btn-purple" onClick={gotItClick}>Got it</button>
                </div>
                {/* <div className="battingCageIntroModal__alternativeContainer">
                    <p>Alternatively, connect one of these accounts.</p>
                    <div className="battingCageIntroModal__imageContainer">
                        <img src="/Group 624930.png" alt="Account 1" />
                        <img src="/Group 624929.png" alt="Account 2" />
                        <img src="/Group 624928.png" alt="Account 3" />
                    </div>
                </div> */}
            </div>
        </Modal>
    );
};
export default BattingCageIntroModal;
