import './TwoColumns.scss';

interface TwoColumnsProps {
    left: React.ReactNode,    
    right: React.ReactNode,
    rightSticky?: boolean,
    leftSticky?: boolean,
    customGrid?: boolean
}

const TwoColumns = ({ left, right, rightSticky, leftSticky, customGrid}: TwoColumnsProps) => {
    
    const styleObj: React.CSSProperties = {
        height: "65vh",
        position: "sticky",
        top: "40px",
    };

    const gridObj: React.CSSProperties = customGrid ? {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: '0'
    } : {};


    const paddingStyle: React.CSSProperties = customGrid ? { padding: '0' } : {};

  
    
    return (
        <div className="twoColumns" style={paddingStyle}>
            <div className="twoColumns__container" style={gridObj}>
                <div className="twoColumns__left" style={leftSticky ? styleObj : undefined}>
                    {left}
                </div>
                <div className="twoColumns__right" style={rightSticky ? styleObj : undefined}>
                    {right}
                </div>
            </div>
        </div>
    )
}

export default TwoColumns;