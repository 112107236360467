import React, { useEffect, useState } from 'react';

import {
    Switch,
    Route,
    useLocation
} from "react-router-dom";

import ReactGA from "react-ga4";

import ScrollToTop from './utilComponents/ScrollToTop';

//LAYOUT
import Navbar from "./layout/navbar/Navbar";

//COMPONENTS
// import SecrurityCodeGenerator from "./components/securityCodeGenerator/SecrurityCodeGenerator";

//PAGES
import AccountSettings from './pages/accountSettings/AccountSettings';
import Articles from './pages/articles/Articles';
import BattingCage from "./pages/battingCage/BattingCage";
import CreateAccount from './pages/CreateAccount';
import CreateProblemSet from "./pages/CreateProblemSet";
import CSSChallenge from './pages/CSSChallenge';
import EditProblemSet from "./pages/editProblemSet/EditProblemSet";
import FellowDetail from './pages/FellowDetail';
import Home from './pages/Home';
import HowToUse from './pages/HowToUse';
import LogicAssessment from "./pages/logicAssessment/LogicAssessment";
import Login from './pages/Login';
import OrgAccountManagement from "./pages/orgAccountManagement/OrgAccountManagement";
import ProblemSets from "./pages/problemSets/ProblemSets";
import ResetPassword from "./pages/ResetPassword";
import Sales from './pages/sales/Sales';
import SecurityCodeGenerator from './pages/SecurityCodeGenerator';
import Videos from "./pages/videos/Videos";
import GuestLandingPage from './pages/GuestLandingPage';
import HowDoesItWorkPage from './pages/HowDoesItWorkPage'

const Routes = ({ subdomain, orgConfig, expandMenu, setExpandMenu, navData }) => {

    const location = useLocation();

    if (process.env.NODE_ENV === 'production') {
        const googleAnalyticsId = orgConfig.googleAnalyticsId;
        ReactGA.initialize(googleAnalyticsId);
    }

    const [loggedIn, setLoggedIn] = useState(localStorage.getItem('accessToken') ? true : false);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location])

    return (
        <>
            <ScrollToTop />
            <Navbar menuData={navData} loggedIn={loggedIn} setLoggedIn={setLoggedIn} expandMenu={expandMenu} setExpandMenu={setExpandMenu} />
            <Switch>
                <Route path="/howToUse">
                    <HowToUse />
                </Route>
                <Route path="/howDoesItWork">
                    <HowDoesItWorkPage />
                </Route>
                <Route path="/accountManagement">
                    <OrgAccountManagement subdomain={subdomain} orgConfig={orgConfig} />
                </Route>
                <Route path="/createAccount">
                    <CreateAccount setLoggedIn={setLoggedIn} subdomain={subdomain} orgConfig={orgConfig} />
                </Route>
                <Route path="/login">
                    <Login setLoggedIn={setLoggedIn} />
                </Route>
                <Route path="/account-settings">
                    <AccountSettings setLoggedIn={setLoggedIn} subdomain={subdomain} orgConfig={orgConfig} />
                </Route>
                <Route path="/profile" component={props => <FellowDetail subdomain={subdomain} orgConfig={orgConfig} />} />
                <Route path="/generateCode">
                    <SecurityCodeGenerator />
                </Route>
                <Route path="/resetPassword">
                    <ResetPassword subdomain={subdomain} />
                </Route>
                <Route path="/fellow/:fellowId">
                    <FellowDetail subdomain={subdomain} />
                </Route>
                <Route path="/problemsets/:problemSetId/edit">
                    <EditProblemSet />
                </Route>
                <Route path="/problemsets/new">
                    <CreateProblemSet />
                </Route>
                <Route path="/problemsets">
                    <ProblemSets />
                </Route>
                <Route path="/learnMore">
                    <Sales />
                </Route>
                <Route path="/videos">
                    <Videos />
                </Route>
                <Route path="/articles">
                    <Articles />
                </Route>
                <Route path="/battingcage">
                    <BattingCage />
                </Route>
                <Route path="/cssChallenges/:challengeSlug">
                    <CSSChallenge loggedIn={loggedIn} />
                </Route> 
                <Route path="/assessment">
                    <LogicAssessment />
                </Route>
                <Route path="/">
                    {subdomain === 'codetrack' && !loggedIn && (<GuestLandingPage/>)}
                    {subdomain === 'codetrack' && loggedIn && <Home orgConfig={orgConfig} userType={orgConfig.userType} subdomain={subdomain} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />}
                    {subdomain !== 'codetrack' && <Home orgConfig={orgConfig} userType={orgConfig.userType} subdomain={subdomain} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />}
                </Route>
            </Switch>
        </>
    )
}

export default Routes