import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import './CodeTrackIntroVideo.scss';

const CodeTrackIntroVideo = () => {
    const history = useHistory(); 

    const handleSignUpClick = () => {
        history.push('/createAccount'); 
    };

    return (
        <div className='codeTrackIntroVideo'>
            <div className='codeTrackIntroVideo__textContainer'>
                <h3>What is CodeTrack?</h3>
                <p>
                    This application is set up to help you track your progress and consistency in working with <br />
                    Codewars. Every time you complete a problem, an event will be emitted that updates your <br />
                    total points, your weekly points and your monthly points.
                </p>
                <h4>Watch this video to learn more.</h4>
            </div>
            <div style={{"position": "relative"}}>
                <iframe src="https://www.loom.com/embed/03fbe7d6b6b44dc7a1bb05138c77fd31?sid=c44e8f9b-bc16-49bb-b7ed-0875c621342d" frameBorder="0" allowFullScreen style={{"position": "relative", "top": 0, "left": 0, "width": "400px", "height": "300px"}}></iframe>
            </div>
           <div className='codeTrackIntroVideo__signup'>
                <Button className='btn-purple' onClick={handleSignUpClick}>
                    Sign Up
                </Button>
                </div>
        </div>
    );
};

export default CodeTrackIntroVideo;
