import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '../../utilComponents/modal/Modal';
import './battingCageModal.scss'; 

interface BattingCageModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    learnMoreClick: () => void; 
}

const BattingCageModal: React.FC<BattingCageModalProps> = ({ showModal, setShowModal, learnMoreClick }) => {
    const history = useHistory(); 

    const startPlayingClick = () => {
        history.push('/battingcage'); 
    };
    return (
        <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            title=""
            customStyling={true}
            showSkipButton={false}
        >
            <div className="howDoesItWork_battingCageModal">
                <div className='howDoesItWork_battingCageModal__image'>
                <img src="Mask group.png" alt="Batting Cage" />
                </div>
                
                <div className="howDoesItWork_battingCageModal__content">
                    <div className="howDoesItWork_battingCageModal__content__text">
                    <h1 className="howDoesItWork_battingCageModal__content__title">The Batting Cage</h1>
                    <p>
                        You're going to play through the first 5 problems on Batting Cage and receive your first points.
                    </p>
                    </div>
                    <div className="howDoesItWork_battingCageModal__content__buttons">
                        <button className="btn-purple learnMore" onClick={learnMoreClick}>Watch Video</button>
                        <button className="btn-purple" onClick={startPlayingClick}>Start Playing</button>
                    </div>
                </div>
                {/* <div className="battingCageModal__alternativeContainer">
                    <p>Alternatively, connect one of these accounts.</p>
                    <div className="battingCageModal__imageContainer">
                        <img src="/Group 624930.png" alt="Account 1" />
                        <img src="/Group 624929.png" alt="Account 2" />
                        <img src="/Group 624928.png" alt="Account 3" />
                    </div>
                </div> */}
            </div>
        </Modal>
    );
};

export default BattingCageModal;
