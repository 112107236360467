// Layout Components
import PageLayout from "../layoutComponents/pageLayout/PageLayout"

// Features
import HowDoesItWork from "../features/howDoesItWork";


const HowDoesItWorkPage = () => {
    return (
        <PageLayout>
           <HowDoesItWork />
        </PageLayout>
    )
};

export default HowDoesItWorkPage;
