import TwoColumns from "../../layoutComponents/twoColumns/TwoColumns"
import { Button } from "@material-ui/core";
import '../heroBlock/heroBlock.scss'

interface HeroBlockProps {
    scrollTarget: string
}

const HeroBlock=({scrollTarget}: HeroBlockProps) => {
    
    const scrollToBottom = () => {
        const bottomSection = document.querySelector(scrollTarget);
        if (bottomSection) {
            bottomSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const leftContent = (
        <div className='heroBlock__leftContainer'>
            <h1>Welcome<br/>to CodeTrack.</h1>
            <p>
                Codetrack syncs with all kinds of accounts<br/> like Codewars, Leetcode, and GitHub.
            </p>
            <Button className='btn-purple' onClick={scrollToBottom}>
                Learn More
            </Button>
        </div>
    );

    const rightContent = (
        <div className='heroBlock__rightContainer'>
            <img src="/HERO IMAGE (1).png" alt="Hero Image" />
        </div>
    );

    return (
        <div>
            <TwoColumns 
                    left={leftContent} 
                    right={rightContent}
                    customGrid={true} 
                />
        </div>
    )
}

export default HeroBlock