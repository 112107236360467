import React from 'react';
import styled from 'styled-components';

interface Article {
  title: string;
  subtitle: string;
  author: string;
  authorImage: string;
  articleSlug: string;
  image: string;
  tags: string[];
}

const ArticlePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0;
  }
`;

const ArticleImage = styled.div`
  img {
    width: 250px;
    height: 150px;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const ArticleContent = styled.div`
  padding: 15px;
`;

const ArticleTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ArticleAuthor = styled.p`
  font-size: 14px;
  color: #6B7280;
  margin-bottom: 10px;
`;

const ArticleSubtitle = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

const ArticleTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ArticleTag = styled.span`
  background-color: #F3F4F6;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #3B82F6;
`;

const ArticlePreview: React.FC<{ article: Article }> = ({ article }) => {
  const { title, subtitle, author, authorImage, image, tags, articleSlug } = article;
  return (
    <ArticlePreviewContainer>
      <ArticleImage>
        <img src={image} alt="Article Image" />
      </ArticleImage>
      <a target="_blank" href={`https://medium.com/@jordan_92311/${articleSlug}`}>
        <ArticleContent>
          <ArticleTitle>{title}</ArticleTitle>
          <ArticleAuthor>Written by: {author}</ArticleAuthor>
          <ArticleSubtitle>{subtitle}</ArticleSubtitle>
          <ArticleTags>
            {tags.map((tag: string, index: number) => (
              <ArticleTag key={index}>{tag}</ArticleTag>
            ))}
          </ArticleTags>
        </ArticleContent>
      </a>
    </ArticlePreviewContainer>
  )
}

export default ArticlePreview